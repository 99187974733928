import React, { useEffect, useRef, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderTitle,
	NavigationHeaderPlaceholder
} from 'components/Navigation/Header';
import { useTranslation } from 'react-i18next';
import { ScrollView, View } from 'react-native';

import { useExport } from 'hooks/useExport';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import i18next from 'i18next';
import { _getPasswordResetEmail } from 'config/mailing';
import { hsTopScreenPadding } from 'config/styleConstants';
import { getTrackingActions, getTrackingTypes, IReport, IReportData, TTrackingAction, TTrackingContentType } from 'config/interfaces';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { Dropdown } from 'components/Form/Dropdown';
import { HSCard } from 'components/Card';
import { FormCalendarPicker, FormReferenceSelect } from 'components/Form';
import { RoundButton } from 'components/Button';
import { EDefaultIconSet } from 'helper';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { H2 } from 'components/Text';
import { ReportListItem } from 'components/Report';
import { NoData } from 'components/NoData';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.Reports>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.Reports>;
type RouteParams = StackParamList[ERoutes.Reports];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'reports';

export const ReportsScreen = ({ route, navigation }: Props) => {
	const { t }: { t: any } = useTranslation();
	const { screenWidth } = useQuery();
	const { activeSpace } = useSpace();
	const { exportReport } = useExport();

	const scrollRef = useRef<ScrollView>(null);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedType, setSelectedType] = useState<TTrackingContentType | undefined>(undefined);
	const [selectedActions, setSelectedActions] = useState<TTrackingAction[]>([]);
	const [selectedItemIds, setSelectedItemIds] = useState<number[]>([]);
	const [fromDate, setFromDate] = useState<string | undefined>(undefined);
	const [untilDate, setUntilDate] = useState<string | undefined>(undefined);
	const [reports, setReports] = useState<IReportData[] | undefined>(undefined);

	const attendees = useSelector((store: IRootState) => store.attendee.attendees);
	const votes = useSelector((store: IRootState) => store.vote.votes);
	const content = useSelector((store: IRootState) => store.content.content);

	const loadReports = useRematchDispatch((dispatch: Dispatch) => dispatch.tracking.loadReports);

	useEffect(() => {
		setSelectedItemIds([]);
		setSelectedActions([]);
	}, [selectedType]);

	const getTitleForItem = (type: TTrackingContentType, itemId?: string) => {
		let title = '';

		switch (type) {
			case 'attendee':
				const attendee = attendees?.find((e) => e.id === Number(itemId));
				if (attendee) {
					title = `${attendee.firstName} ${attendee.lastName}`;
				}
				break;
			case 'space':
				title = activeSpace?.title ?? '';
				break;
			case 'vote':
				title = votes?.find((e) => e.id === Number(itemId))?.question ?? '';
				break;
			default:
				let _type;
				switch (type) {
					case 'feed':
						_type = 'newsitems';
						break;
					default:
						_type = `${type}s`;
						break;
				}

				if (content && content[_type]) {
					title = content[_type].find((e) => e.id === Number(itemId))?.title ?? '';
				}
				break;
		}

		title += ` (ID: ${itemId})`;

		return title;
	};

	const _loadReports = async () => {
		if (selectedType) {
			setIsLoading(true);
			const res: IReport[] = await loadReports({
				type: selectedType,
				itemIds: selectedItemIds,
				actions: selectedActions,
				from: fromDate,
				until: untilDate
			});

			const _reports: typeof reports = [];

			res.forEach((item) => {
				const itemDate = moment(item.date).format('YYYY-MM-DD');
				item.itemId = item.itemId ?? `space_${itemDate}`;

				const foundItemId = _reports.find((e) => e.itemId === item.itemId);
				if (foundItemId) {
					const foundAction = foundItemId.items.find((e) => e.action === item.action);
					if (foundAction) {
						if (!foundAction.data) {
							foundAction.data = {};
						}
						if (!foundAction.data?.userIds) {
							foundAction.data.userIds = [];
						}

						if (item.data?.userIds) {
							item.data.userIds.forEach((userId) => {
								if (!foundAction.data?.userIds.includes(userId)) {
									foundAction.data?.userIds.push(userId);
								}
							});
						}
					} else {
						foundItemId.items.push(item);
					}
				} else {
					_reports.push({
						itemId: item.itemId,
						items: [item],
						type: item.type,
						date: item.date
					});
				}
			});

			_reports.sort((a, b) => (a.date > b.date ? -1 : 1));

			_reports.forEach((report) => {
				report.items.sort((a, b) => {
					const aVal =
						moment(a.date).format('DD.MM.YYYY') +
							' ' +
							getTrackingActions(a.type)
								.find((e) => e.key === a.action)
								?.label.toLowerCase() ?? '';
					const bVal =
						moment(b.date).format('DD.MM.YYYY') +
							' ' +
							getTrackingActions(b.type)
								.find((e) => e.key === b.action)
								?.label.toLowerCase() ?? '';

					return aVal > bVal ? -1 : 1;
				});
			});

			setReports(_reports);
			setTimeout(() => {
				scrollRef.current?.scrollTo({
					y: 500,
					animated: true
				});
			}, 500);

			setIsLoading(false);
		}
	};

	const _renderItemSelection = () => {
		if (selectedType) {
			switch (selectedType) {
				case 'attendee':
				case 'chat':
				case 'meeting':
				case 'space':
					return null;
				default:
					let type;

					switch (selectedType) {
						case 'feed':
							type = 'newsitems';
							break;
						case 'vote':
							type = 'votings';
							break;
						default:
							type = `${selectedType}s`;
							break;
					}

					return (
						<FormReferenceSelect
							testID={`${TESTIDPREFIX}_dropdown_items`}
							label={t('Entries')}
							hint={t('ReportsItemsDropdownHint')}
							type={type}
							multiSelect
							value={selectedItemIds}
							onSelect={(selection) => setSelectedItemIds(selection)}
						/>
					);
			}
		}

		return null;
	};

	const _renderReports = () => {
		if (reports) {
			if (reports.length === 0) {
				return <NoData type="NoReports" />;
			}

			return (
				<HSCard>
					{reports.map((e) => {
						const title = getTitleForItem(e.type, e.itemId);
						return (
							<View style={{}} key={`report_${e.itemId}`}>
								<H2 center>{title}</H2>

								{e.items.map((item, itemIdx) => (
									<ReportListItem
										key={`report_${e.itemId}_${itemIdx}`}
										testID={`report_${e.itemId}_${item.id}`}
										item={item}
										onPress={() => exportReport(item, title)}
									/>
								))}
							</View>
						);
					})}
				</HSCard>
			);
		}

		return null;
	};

	return (
		<ScreenContainer isProtectedRoute>
			<ScrollView
				ref={scrollRef}
				contentContainerStyle={{
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: hsTopScreenPadding,
					width: screenWidth,
					alignSelf: 'center'
				}}
				testID={`${TESTIDPREFIX}_scrollview`}
			>
				<HSCard>
					<Dropdown
						testID={`${TESTIDPREFIX}_dropdown_type`}
						label={t('Type')}
						isRequired
						hint={t('ReportTypeHint')}
						onSelect={(val) => {
							if (val === 'space') {
								setSelectedActions([]);
							}
							setSelectedType(val);
						}}
						options={getTrackingTypes()}
						value={selectedType}
						isDisabled={isLoading}
					/>
					{_renderItemSelection()}
					{selectedType && (
						<Dropdown
							testID={`${TESTIDPREFIX}_dropdown_actions`}
							label="Actions"
							hint={t('ReportActionsHint')}
							onSelect={(val) => setSelectedActions(val)}
							options={getTrackingActions(selectedType)}
							value={selectedActions}
							isDisabled={isLoading}
							multi
						/>
					)}
					{selectedType && (
						<FormCalendarPicker
							testID={`${TESTIDPREFIX}_calendar_date`}
							startDate={fromDate}
							endDate={untilDate}
							maxDate={moment().toISOString()}
							allowRangeSelection
							label={t('Date')}
							hint={t('ReportDateHint')}
							onStartDateChange={(date) => setFromDate(date ?? undefined)}
							onEndDateChange={(date) => setUntilDate(date ?? undefined)}
							isDisabled={isLoading}
							onReset={() => {
								setFromDate(undefined);
								setUntilDate(undefined);
							}}
						/>
					)}
					<RoundButton
						testID={`${TESTIDPREFIX}_button_load`}
						icon={EDefaultIconSet.Analytics}
						title={t('Load Reports')}
						alignSelf="flex-end"
						isLoading={isLoading}
						isDisabled={!selectedType}
						onPress={_loadReports}
					/>
				</HSCard>
				{_renderReports()}
			</ScrollView>
		</ScreenContainer>
	);
};

export const ReportsScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title={i18next.t('Reports')} />
			<NavigationHeaderPlaceholder />
		</NavigationHeader>
	);
};
