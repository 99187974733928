import moment from 'moment';
import { ILauncherSpace } from './interfaces';

export const ENVIRONMENT: 'dev' | 'stage' | 'prod' | 'bits' | 'k5' | 'nwx' | 'metro' | 'lmcl' = 'lmcl';
export const DEFAULT_MULTISPACE_URL = 'https://multispace-lmcl.hspx.de';
export const DEFAULT_PLATFORM_URL = 'https://app-lmcl.hellospaces.de/';
export const DEFAULT_PLATFORM_SHARE_URL = 'https://app-lmcl.hellospaces.de/app/spacesummary/';
export const PRESET_SPACEIDS: string[] | undefined = ['lmcl'];
export const PRESET_SPACE_FALLBACK: ILauncherSpace | undefined = {
	id: 2,
	created_at: moment().toISOString(),
	endDate: moment().toISOString(),
	multispaceId: 2,
	multispaceUrl: 'https://multispace-lmcl.hspx.de',
	published_at: moment().toISOString(),
	spaceId: 'lmcl',
	startDate: moment().toISOString(),
	title: 'Last Mile City Logistics',
	updated_at: moment().toISOString()
};
export const SENTRY_DSN = 'https://228e157359ea4abba6e1be50bc447a35@sentry.hspx.de/32';
export const MIN_BUILD_VERSION_IOS = 1;
export const MIN_BUILD_VERSION_ANDROID = 1;
export const APP_STORE_URL_IOS = 'https://apps.apple.com/us/app/lmcl/id1662365046';
export const APP_STORE_URL_ANDROID = 'https://play.google.com/store/apps/details?id=de.hellospaces.lmcl';
export const DEEPLINK_SHORT = 'lmcl://';

export const APP_LANGUAGE: 'de' | 'en' | undefined = 'de';

export const QuotaLimits = {
	attendees: 50,
	admins: 1,
	moderators: 1
};
