import React, { ReactNode, useState } from 'react';
import { FlatList, Linking, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';

import { Image, PlaceholderGradient } from 'components/Image';
import { ChildButton } from 'components/Button/ChildButton';
import { RoundButton } from 'components/Button/RoundButton';
import { ShareButton } from 'components/Button/ShareButton';
import { FormCheckbox, FormMultiSwitch, FormTagCloud } from 'components/Form';
import { Icon } from 'components/Icon';
import {
	IExpo,
	ILauncherSpace,
	IMediaItem,
	IRegistrationField,
	IRenderSectionProps,
	ISpeaker,
	TDetailViewType,
	TTrackingAction,
	TTrackingContentType
} from 'config/interfaces';
import {
	EDefaultColorSet,
	EDefaultIconSet,
	getCardContainerStyle,
	getDefaultAspectRatioStyle,
	getSettingsForNavigationKey,
	isEmptyString,
	IS_WEB,
	openURL
} from 'helper';
import { useTheme } from './useTheme';
import { H2, Subtitle, Text, Title } from 'components/Text';
import { ScheduleReferenceItem } from 'components/Schedule/ScheduleReferenceItem';
import { ERoutes } from 'components/Navigation/routes';
import { Markdown } from 'components/Markdown';
import { SpeakerReferenceItem } from 'components/Speaker/SpeakerReferenceItem';
import { ExpoReferenceItem } from 'components/Expo/ExpoReferenceItem';
import { VoteReferenceItem } from 'components/Vote/VoteReferenceItem';
import { useQuery } from './useQuery';
import { useTranslation } from 'react-i18next';
import { MediaItemReferenceItem } from 'components/MediaItem';
import { AttendeeReferenceItem } from 'components/Attendee';
import { useSpace } from './useSpace';
import { hsBlue, hsBorderRadius, hsBottomMargin, hsTextBottomMarginSmall } from 'config/styleConstants';
import { SpeakerModal } from 'components/Modal/SpeakerModal';
import { useTracker } from './useTracker';
import { FlashList } from 'components/List';
import { ContentCard } from 'components/Card/ContentCard';
import { HSCard } from 'components/Card/HSCard';
import { getIsImageOrVideoByExtension } from 'helper/media';
import { IFormSection, useContent } from './useContent';
import { MediaItemListItem } from 'components/MediaItem/MediaItemListItem';
import { FavoriteButton } from 'components/Button/FavoriteButton';
import { Avatar } from 'components/User/Avatar';
import { useRegistration } from './useRegistration';
import { EXPO_DETAIL_IMAGEWIDTH, EXPO_DETAIL_IMAGEWIDTH_WEB } from 'config/constants';

const BOTTOMMARGIN = hsBottomMargin;

export const useDetail = () => {
	const navigation = useNavigation();
	const { theme } = useTheme();
	const { screenColumnCount, isTabletOrMobile, screenColumnCountSmallItems } = useQuery();
	const { t } = useTranslation();
	const { activeSpace, iAmSpaceAdmin, iAmSpaceModerator } = useSpace();
	const { getContentTypeSections: getScheduleSections } = useContent('schedule');
	const { getContentTypeSections: getExpoSections } = useContent('expo');
	const { getContentTypeSections: getSpeakerSections } = useContent('speaker');
	const { getContentTypeSections: getMediaItemSections } = useContent('mediaitem');
	const { getRegistrationSections } = useRegistration();
	const { trackAction } = useTracker();

	const mediaDetail = useSelector((store: IRootState) => store.temp.mediaDetail);
	const content = useSelector((store: IRootState) => store.content.content);

	const setMediaDetail = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setMediaDetail);
	const setMediaDetailViewType = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setMediaDetailViewType);
	const setIsContactModalVisible = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setIsContactModalVisible);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	const [isSpeakerModalVisible, setIsSpeakerModalVisible] = useState<boolean>(false);
	const [speaker, setSpeaker] = useState<ISpeaker | undefined>(undefined);

	const _getSettings = (field: IRegistrationField) => {
		const obj: {
			icon: string;
			color: string;
			trackingAction: TTrackingAction;
		} = {
			icon: EDefaultIconSet.Save,
			color: theme.text,
			trackingAction: 'Open Website'
		};

		switch (field.fieldName) {
			case 'linkedInProfileUrl':
				obj.icon = EDefaultIconSet.LinkedIn;
				obj.color = EDefaultColorSet.LinkedIn;
				obj.trackingAction = 'Open LinkedIn';
				break;
			case 'xingProfileUrl':
				obj.icon = EDefaultIconSet.Xing;
				obj.color = EDefaultColorSet.Xing;
				obj.trackingAction = 'Open XING';
				break;
			case 'twitterProfileUrl':
				obj.icon = EDefaultIconSet.Twitter;
				obj.color = EDefaultColorSet.Twitter;
				obj.trackingAction = 'Open Twitter';
				break;
			case 'facebookProfileUrl':
				obj.icon = EDefaultIconSet.Facebook;
				obj.color = EDefaultColorSet.Facebook;
				obj.trackingAction = 'Open Facebook';
				break;
			case 'instagramProfileUrl':
				obj.icon = EDefaultIconSet.Instagram;
				obj.color = EDefaultColorSet.Instagram;
				obj.trackingAction = 'Open Instagram';
				break;
			case 'youTubeProfileUrl':
				obj.icon = EDefaultIconSet.YouTube;
				obj.color = EDefaultColorSet.YouTube;
				obj.trackingAction = 'Open YouTube';
				break;
			case 'tikTokProfileUrl':
				obj.icon = EDefaultIconSet.TikTok;
				obj.color = EDefaultColorSet.TikTok;
				obj.trackingAction = 'Open TikTok';
				break;
			case 'blogUrl':
				obj.icon = EDefaultIconSet.Blog;
				obj.trackingAction = 'Open Blog';
				break;
			case 'website':
				obj.icon = EDefaultIconSet.Website;
				obj.trackingAction = 'Open Website';
				break;
			default:
				break;
		}

		return obj;
	};

	const renderSocialMedia = (
		type: TTrackingContentType,
		fields: IRegistrationField[],
		item,
		TESTIDPREFIX: string,
		prependedFields?,
		appendedFields?
	) => {
		const _fields = fields.filter(
			(e) =>
				(e.fieldType === 'website' || e.fieldType === 'socialmedia') && e.showOnDetailScreen !== false && e.visibility !== 'never'
		);

		let elements: ReactNode[] = [];
		if (prependedFields) {
			elements = elements.concat(prependedFields);
		}

		if (_fields.length > 0) {
			_fields.forEach((field) => {
				if (item && !isEmptyString(item[field.fieldName])) {
					const { icon, color, trackingAction } = _getSettings(field);

					elements.push(
						<ChildButton
							key={`${TESTIDPREFIX}_button_${field.fieldName}`}
							testID={`${TESTIDPREFIX}_button_${field.fieldName}`}
							style={{ marginHorizontal: 5, justifyContent: 'center' }}
							onPress={() => {
								openURL(item[field.fieldName]);
								trackAction(type, trackingAction, `${item.id}`);
							}}
						>
							<Icon name={icon} size={field.fieldName.includes('ProfileUrl') ? 25 : 23} color={color} />
						</ChildButton>
					);
				}
			});
		}

		if (appendedFields) {
			elements = elements.concat(appendedFields);
		}

		if (elements.length > 0) {
			return (
				<View style={{ flexDirection: 'row', marginBottom: BOTTOMMARGIN, flexWrap: 'wrap', justifyContent: 'center' }}>
					{elements}
				</View>
			);
		}

		return null;
	};

	const _renderImage = (
		TESTIDPREFIX: string,
		item: IExpo | ISpeaker,
		field: IRegistrationField,
		viewType?: TDetailViewType,
		imageWidth?: string | number
	) => {
		const imageItem = item[field.fieldName];
		let url: string | number = '';

		if (typeof imageItem === 'number') {
			url = imageItem;
		} else if (typeof imageItem === 'string') {
			url = imageItem;
		} else if (imageItem?.url) {
			url = imageItem.url;
		}
		let isFile = false;
		let extension = '';
		try {
			if (imageItem?.mime?.includes('image')) {
				isFile = false;
			} else if (typeof url === 'string') {
				if (url.startsWith('data:image') || url.startsWith('/static/')) {
					isFile = false;
				} else {
					const splitted = url.split('/');
					if (splitted) {
						const _extension = splitted[splitted.length - 1].split('.')[1];
						extension = _extension;
						isFile = !getIsImageOrVideoByExtension(_extension);
					}
				}
			}
		} catch (error) {}

		if (url) {
			const _width = imageWidth
				? imageWidth
				: viewType !== 'stacked'
				? '100%'
				: IS_WEB && !isTabletOrMobile
				? EXPO_DETAIL_IMAGEWIDTH_WEB
				: EXPO_DETAIL_IMAGEWIDTH;
			return (
				<Image
					testID={`${TESTIDPREFIX}_image_${field.fieldName}`}
					style={{
						...getDefaultAspectRatioStyle(_width),
						borderRadius: hsBorderRadius,
						alignSelf: 'center',
						marginBottom: hsTextBottomMarginSmall
					}}
					url={url}
					expectedRatio={16 / 9}
					imageSize={IS_WEB ? 'full' : 'small'}
					resizeMode={'contain'}
				/>
			);
		}
	};

	const renderField = (
		field: IRegistrationField,
		item,
		TESTIDPREFIX,
		detailContentType,
		space?: ILauncherSpace,
		isSelfService?: boolean,
		logoWidth?: string | number
	) => {
		if (item) {
			let viewType;
			switch (detailContentType) {
				case 'expo':
					viewType = space?.expoDetailViewType ?? space?.globalDetailViewType;
					break;
				case 'speaker':
					viewType = space?.speakerDetailViewType ?? space?.globalDetailViewType;
					break;
				default:
					break;
			}

			switch (field.fieldType) {
				case 'boolean':
					return (
						<FormCheckbox
							style={{ marginBottom: BOTTOMMARGIN }}
							key={`${TESTIDPREFIX}_checkbox_${field.fieldName}`}
							testID={`${TESTIDPREFIX}_checkbox_${field.fieldName}`}
							isDisabled
							value={item[field.fieldName]}
							label={field.fieldLabel}
						/>
					);
				case 'title':
					if (!isEmptyString(item[field.fieldName])) {
						return <Title style={{ marginTop: 20, marginBottom: 5, textAlign: 'center' }}>{item.title}</Title>;
					}
					return null;
				case 'subtitle':
					if (!isEmptyString(item[field.fieldName])) {
						return <Subtitle style={{ textAlign: 'center' }}>{item.catchphrase}</Subtitle>;
					}
					return null;
				case 'string':
				case 'website':
					if (!isEmptyString(item[field.fieldName])) {
						const isLink = item[field.fieldName].startsWith('https') || item[field.fieldName].startsWith('http');
						return (
							<View key={`${TESTIDPREFIX}_text_${field.fieldName}`} style={{ marginBottom: BOTTOMMARGIN }}>
								<Text bold style={{ marginBottom: 5 }}>
									{field.fieldLabel}
								</Text>
								{isLink ? (
									<ChildButton
										testID={`${field.fieldName}_link_${item[field.fieldName]}`}
										onPress={() => openURL(item[field.fieldName])}
									>
										<Text
											style={{
												color: hsBlue,
												textDecorationLine: 'underline',
												textDecorationColor: hsBlue,
												textDecorationStyle: 'solid'
											}}
										>
											{item[field.fieldName]}
										</Text>
									</ChildButton>
								) : (
									<Text>{item[field.fieldName]}</Text>
								)}
							</View>
						);
					}

					return null;

				case 'phoneNumber':
					if (!isEmptyString(item[field.fieldName])) {
						return (
							<ChildButton
								key={`${TESTIDPREFIX}_text_${field.fieldName}`}
								style={{ marginBottom: BOTTOMMARGIN }}
								onPress={() => {
									let phoneNumber = item[field.fieldName];
									phoneNumber = phoneNumber.replace(/\s/g, '');
									Linking.openURL(`tel:${phoneNumber}`);
								}}
								testID={''}
								activeOpacity={1}
							>
								<Text bold style={{ marginBottom: 5 }}>
									{field.fieldLabel}
								</Text>
								<Text selectable>{item[field.fieldName]}</Text>
							</ChildButton>
						);
					}

					return null;
				case 'email':
					if (!isEmptyString(item[field.fieldName])) {
						return (
							<ChildButton
								key={`${TESTIDPREFIX}_text_${field.fieldName}`}
								style={{ marginBottom: BOTTOMMARGIN }}
								onPress={() => Linking.openURL(`mailto:${item[field.fieldName]}`)}
								testID={''}
								activeOpacity={1}
							>
								<Text bold style={{ marginBottom: 5 }}>
									{field.fieldLabel}
								</Text>
								<Text selectable>{item[field.fieldName]}</Text>
							</ChildButton>
						);
					}

					return null;
				case 'contactEmail':
					if (!isEmptyString(item[field.fieldName])) {
						return (
							<View key={`${TESTIDPREFIX}_button_contact`} style={{ flexDirection: 'row', justifyContent: 'center' }}>
								<RoundButton
									testID={`${TESTIDPREFIX}_button_contact`}
									title={t('contactButtonText')}
									icon={EDefaultIconSet.Mail}
									onPress={() => setIsContactModalVisible(true)}
									isStacked={!isTabletOrMobile}
									isDisabled={isSelfService}
								/>
							</View>
						);
					}
					return null;

				case 'location':
					if (!isEmptyString(item[field.fieldName])) {
						const spaceMaps = content?.maps.filter((m) => m.spaceId === activeSpace?.spaceId && !m.isDeleted);

						const _mapPosition = item?.mappositions?.find(
							(e) =>
								e.map &&
								spaceMaps?.find((m) => (typeof e.map === 'number' ? m.id === e.map : m.id === e.map?.id)) &&
								!e.isDeleted
						);

						const mapPosition = content?.mappositions?.find((e) => e.id === _mapPosition?.id && !e.isDeleted);

						if (mapPosition) {
							return (
								<View style={{ flexDirection: 'row', justifyContent: 'center' }} key={`${TESTIDPREFIX}_button_map`}>
									<RoundButton
										testID={`${TESTIDPREFIX}_button_map`}
										title={
											item.location !== null && !isEmptyString(item.location)
												? item.location
												: t('locationButtonText')
										}
										icon={EDefaultIconSet.MapMarker}
										isDisabled={isSelfService}
										onPress={() => {
											trackAction('expo', 'Show On Map', item.id.toString());
											navigation.navigate(ERoutes.Maps, {
												spaceId: activeSpace?.spaceId,
												mapId: mapPosition?.map?.id,
												positionId: mapPosition.id,
												key: 'maps'
											});
										}}
										isStacked={!isTabletOrMobile}
									/>
								</View>
							);
						}
					}

					return null;
				case 'markdown':
					if (!isEmptyString(item[field.fieldName])) {
						return (
							<View key={`${TESTIDPREFIX}_text_${field.fieldName}`} style={{ marginBottom: BOTTOMMARGIN }}>
								{field.fieldName !== 'description' ? <Text bold style={{ marginBottom: 5 }}>
									{field.fieldLabel}
								</Text>: null}
								<Markdown markdown={item[field.fieldName]} />
							</View>
						);
					}

					return null;
				case 'category':
					if (
						field.options?.filter((e) => (item[field.fieldName] ? item[field.fieldName]?.split(',').includes(e.key) : false))
							?.length === 0
					) {
						return null;
					}
					const matching = activeSpace?.attendeeSettings?.matching?.find(
						(e) =>
							(e.type1 === detailContentType && e.fieldName1 === field.fieldName && e.type2 === 'attendee') ||
							(e.type2 === detailContentType && e.fieldName2 === field.fieldName && e.type1 === 'attendee')
					);

					return (
						<FormTagCloud
							formStyle={{ marginBottom: BOTTOMMARGIN }}
							key={`${TESTIDPREFIX}_tagcloud_${field.fieldName}`}
							testID={`${TESTIDPREFIX}_tagcloud_${field.fieldName}`}
							isDisabled
							label={field.fieldLabel}
							options={field.options}
							value={item[field.fieldName]}
							showOnlyValues
							matchProfileCategory={
								matching?.type1 === detailContentType && matching?.fieldName1 === field.fieldName
									? matching.fieldName2
									: matching?.fieldName1
							}
						/>
					);
				case 'section':
					if (!isEmptyString(field.fieldLabel)) {
						return (
							<H2 key={field.fieldName} center>
								{field.fieldLabel}
							</H2>
						);
					}

					return null;

				case 'multiswitch':
					if (!isEmptyString(item[field.fieldName])) {
						return (
							<FormMultiSwitch
								formStyle={{ marginBottom: BOTTOMMARGIN }}
								key={`${TESTIDPREFIX}_multiswitch_${field.fieldName}`}
								testID={`${TESTIDPREFIX}_multiswitch_${field.fieldName}`}
								isDisabled
								label={field.fieldLabel}
								options={field.options}
								value={item[field.fieldName]}
							/>
						);
					}
				case 'image':
					// das muss aus den schedule feldern raus bzw showOnDetails muss false. das fixt es fürs erste
					if (detailContentType === 'schedule' || detailContentType === 'mediaitem') {
						return null;
					}
					if (!item[field.fieldName]) {
						return null;
					}
					return _renderImage(TESTIDPREFIX, item, field, viewType, logoWidth);

				case 'logo':
					switch (detailContentType) {
						case 'speaker':
						case 'attendee':
							if (!item[field.fieldName]) {
								return null;
							}
							return (
								<View style={{ alignItems: 'center', marginBottom: detailContentType === 'speaker' ? hsBottomMargin : 0 }}>
									<Avatar
										testID={`${TESTIDPREFIX}_avatar`}
										size="xxl"
										avatar={item[field.fieldName].url}
										fullName={item.title}
									/>
								</View>
							);

						case 'expo':
							if (!item[field.fieldName]) {
								if (space?.showGradientOnMissingImage) {
									const width =
										typeof logoWidth === 'number'
											? logoWidth
											: IS_WEB
											? EXPO_DETAIL_IMAGEWIDTH_WEB
											: EXPO_DETAIL_IMAGEWIDTH;

									return (
										<View style={{ width: width, alignSelf: 'center' }}>
											<PlaceholderGradient
												itemId={item?.id}
												title={item?.title}
												width={width}
												rounded={'full'}
												smallText
											/>
										</View>
									);
								}
							}
							return _renderImage(TESTIDPREFIX, item, field, viewType, logoWidth);

						default:
							return null;
					}

				default:
					return null;
			}
		}

		return null;
	};

	const _renderReferenceComponent = (
		TESTIDPREFIX: string,
		item: any,
		type?: string,
		isInMediaDetailModal?: boolean,
		isPublicAgenda?: boolean,
		hasStream?: boolean,
		isDisabled?: boolean,
		space?: ILauncherSpace
	) => {
		switch (type) {
			case 'expos':
				const expoOnPress = () => {
					if (isDisabled) return;
					if (isInMediaDetailModal) {
						setMediaDetailViewType('collapsed');
					}
					navigation.navigate(ERoutes.ExpoDetails, {
						id: item.id,
						spaceId: space?.spaceId
					});
				};
				switch (space?.detailReferencesViewType) {
					case 'cards':
						return (
							<ContentCard
								testID={TESTIDPREFIX}
								item={item}
								contentType="expo"
								isLoading={isDisabled}
								onPress={expoOnPress}
							/>
						);
					case 'wideCards':
					default:
						return <ExpoReferenceItem testID={TESTIDPREFIX} item={item} onPress={expoOnPress} />;
				}

			case 'mediaitems':
				return (
					<MediaItemReferenceItem
						testID={TESTIDPREFIX}
						item={item}
						onPress={() => {
							if (isDisabled) return;
							if (!isPublicAgenda) {
								navigation.navigate(ERoutes.Media, {
									itemId: item.id,
									mediaType: 'mediaitem',
									spaceId: space?.spaceId
								});
								setMediaDetail({
									itemId: undefined,
									viewType: 'full',
									playbackStatus: 'paused'
								});
								return;
							}
							if (mediaDetail.itemId) {
								if (mediaDetail.itemId === item.id && mediaDetail.itemType === 'mediaitem') {
									setMediaDetailViewType('full');
								} else if (!IS_WEB && mediaDetail.playbackStatus === 'paused') {
									setMediaDetail({
										itemId: item.id,
										itemType: 'mediaitem',
										viewType: 'full',
										playbackStatus: 'paused'
									});
								} else {
									showAlert({
										title: t('Switch Stream'),
										message: t('Switch Stream Subtitle'),
										buttons: [
											{
												style: 'cancel',
												text: t('Cancel')
											},
											{
												style: 'destructive',
												text: t('Switch Stream'),
												onPress: () =>
													setMediaDetail({
														itemId: item.id,
														itemType: 'mediaitem',
														viewType: 'full',
														playbackStatus: 'paused'
													})
											}
										]
									});
								}
							} else {
								setMediaDetail({
									itemId: item.id,
									itemType: 'mediaitem',
									viewType: 'full',
									playbackStatus: 'paused'
								});
							}
						}}
					/>
				);
			case 'schedules':
				const scheduleOnPress = () => {
					if (isDisabled) return;
					if (!isPublicAgenda) {
						navigation.navigate(ERoutes.Media, {
							itemId: item.id,
							mediaType: 'schedule',
							spaceId: space?.spaceId
						});
						setMediaDetail({
							itemId: undefined,
							viewType: 'full',
							playbackStatus: 'paused'
						});
						return;
					}
					if (mediaDetail.itemId) {
						if (mediaDetail.itemId === item.id && mediaDetail.itemType === 'schedule') {
							setMediaDetailViewType('full');
						} else if (!IS_WEB && mediaDetail.playbackStatus === 'paused') {
							setMediaDetail({
								itemId: item.id,
								itemType: 'schedule',
								viewType: 'full',
								playbackStatus: 'paused'
							});
						} else {
							showAlert({
								title: t('Switch Stream'),
								message: t('Switch Stream Subtitle'),
								buttons: [
									{
										style: 'cancel',
										text: t('Cancel')
									},
									{
										style: 'destructive',
										text: t('Switch Stream'),
										onPress: () =>
											setMediaDetail({
												itemId: item.id,
												itemType: 'schedule',
												viewType: 'full',
												playbackStatus: 'paused'
											})
									}
								]
							});
						}
					} else {
						setMediaDetail({
							itemId: item.id,
							itemType: 'schedule',
							viewType: 'full',
							playbackStatus: 'paused'
						});
					}
				};
				switch (space?.detailReferencesViewType) {
					case 'cards':
						return (
							<ContentCard
								testID={TESTIDPREFIX}
								item={item}
								contentType="schedule"
								isLoading={isDisabled}
								onPress={scheduleOnPress}
							/>
						);
					case 'wideCards':
					default:
						return <ScheduleReferenceItem testID={TESTIDPREFIX} item={item} onPress={scheduleOnPress} />;
				}

			case 'speakers':
				const speakerOnPress = () => {
					if (isDisabled) return;
					if (isPublicAgenda) {
						setSpeaker(item);
						setIsSpeakerModalVisible(true);
					} else {
						if (isInMediaDetailModal && hasStream) {
							setMediaDetailViewType('collapsed');
						} else {
							setMediaDetail({
								itemId: undefined,
								viewType: 'full',
								playbackStatus: 'paused'
							});
						}
						navigation.navigate(ERoutes.SpeakerDetails, {
							id: item.id,
							spaceId: space?.spaceId
						});
					}
				};
				switch (space?.detailReferencesViewType) {
					case 'cards':
						return (
							<ContentCard
								testID={TESTIDPREFIX}
								item={item}
								contentType="speaker"
								isLoading={isDisabled}
								onPress={speakerOnPress}
							/>
						);
					case 'wideCards':
					default:
						return <SpeakerReferenceItem testID={TESTIDPREFIX} item={item} onPress={speakerOnPress} />;
				}

			case 'attendees':
				const attendeeOnPress = () => {
					if (isDisabled) return;
					if (isInMediaDetailModal) {
						setMediaDetailViewType('collapsed');
					}
					navigation.navigate(ERoutes.AttendeeDetails, {
						userId: item.userId
					});
				};
				switch (space?.detailReferencesViewType) {
					case 'cards':
						return (
							<ContentCard
								testID={TESTIDPREFIX}
								item={item}
								contentType="attendee"
								isLoading={isDisabled}
								onPress={attendeeOnPress}
							/>
						);
					case 'wideCards':
					default:
						return <AttendeeReferenceItem testID={TESTIDPREFIX} item={item} onPress={attendeeOnPress} />;
				}

			case 'votings':
				return (
					<VoteReferenceItem
						testID={TESTIDPREFIX}
						item={item}
						onPress={
							item.isActive || iAmSpaceAdmin || iAmSpaceModerator
								? () => {
										if (isDisabled) return;
										// if (isInMediaDetailModal && hasStream) {
										// 	setMediaDetailViewType('collapsed');
										// }
										setMediaDetail({
											itemId: undefined,
											viewType: 'full',
											playbackStatus: 'paused'
										});
										navigation.navigate(ERoutes.ActiveVoting, { spaceId: space?.spaceId, id: item.id });
								  }
								: undefined
						}
					/>
				);
			default:
				return null;
		}
	};

	const renderReferenceField = (
		field: IRegistrationField,
		items: any[],
		TESTIDPREFIX: string,
		space?: ILauncherSpace,
		isInMediaDetailModal?: boolean,
		isPublicAgenda?: boolean,
		hasStream?: boolean,
		isDisabled?: boolean,
		useFieldLabel?: boolean
	) => {
		const feature = space?.features?.list.find((e) => e.key === field.fieldName || e.key === `${field.fieldName}s`);
		const { label } = getSettingsForNavigationKey(field.fieldName, feature);

		const columnCount = screenColumnCount; // space?.detailReferencesViewType === 'cards' ? screenColumnCountSmallItems : screenColumnCount;

		return (
			<View key={`${TESTIDPREFIX}_reference_${field.fieldName}`} style={{ width: '100%' }}>
				{items.length > 0 && (
					<Text bold style={{ marginBottom: 10 }}>
						{!isEmptyString(label) && !useFieldLabel ? label : field.fieldLabel}
					</Text>
				)}
				{columnCount && (
					<FlashList
						data={items}
						numColumns={columnCount}
						renderItem={(renderItem) => (
							<View style={getCardContainerStyle(columnCount, renderItem.index)}>
								{_renderReferenceComponent(
									TESTIDPREFIX,
									renderItem.item,
									field.referenceType,
									isInMediaDetailModal,
									isPublicAgenda,
									hasStream,
									isDisabled,
									space
								)}
							</View>
						)}
						estimatedItemSize={120}
					/>
				)}
				<SpeakerModal speaker={speaker} isVisible={isSpeakerModalVisible} onClose={() => setIsSpeakerModalVisible(false)} />
			</View>
		);
	};

	const renderSections = (props: IRenderSectionProps) => {
		const {
			contentType,
			testID,
			item,
			forbiddenFieldNames,
			forbiddenFieldTypes,
			position,
			isSelfService,
			detailFields,
			space,
			referencedSpeakers,
			referencedMediaItems,
			referencedAttendees,
			referencedSchedules,
			referencedExpos,
			referencedAttendee,
			renderGallery,
			logoWidth
		} = props;

		let contentSections: IFormSection[] = [];
		switch (contentType) {
			case 'expo':
				contentSections = getExpoSections(isSelfService, detailFields, true, space);
				break;
			case 'speaker':
				contentSections = getSpeakerSections(isSelfService, detailFields, true, space);
			case 'attendee':
				contentSections = getRegistrationSections(detailFields);
			default:
				break;
		}

		let hasRenderedSocials = false;
		const sections = contentSections.map((section, idx) => {
			const foundSectionHeader = section.fields.find((f) => f.fieldType === 'section' || f.fieldType === 'mainsection');
			if (foundSectionHeader && position && foundSectionHeader.sectionPosition !== position) {
				if (!(position === 'right' && !foundSectionHeader.sectionPosition)) {
					return null;
				}
			}

			let fields = section.fields
				?.filter(
					(e) =>
						(e.showOnDetailScreen || e.visibility === 'visible' || e.fieldType === 'mainsection') &&
						!forbiddenFieldNames.includes(e.fieldName) &&
						!forbiddenFieldTypes.includes(e.fieldType)
				)
				?.map((field, currentIndex) => {
					let items: any[] = [];
					let videoItems: IMediaItem[] = [];

					switch (field.referenceType) {
						case 'speakers':
							items = referencedSpeakers ?? [];
							break;
						case 'mediaitems':
							(referencedMediaItems ?? []).forEach((item: IMediaItem) => {
								videoItems.push(item);
							});
							break;
						case 'attendees':
							if (referencedAttendee) {
								items = [referencedAttendee] ?? [];
							} else {
								items = referencedAttendees ?? [];
							}
							break;
						case 'schedules':
							items = referencedSchedules ?? [];
							break;
						case 'expos':
							items = referencedExpos ?? [];
							break;
						default:
							break;
					}

					if (field.fieldType === 'gallery' && renderGallery) {
						return {
							type: field.fieldType,
							render: renderGallery()
						};
					}

					if (
						(field.fieldType === 'reference' || field.fieldType === 'singlereference') &&
						(items.length > 0 || videoItems.length > 0)
					) {
						if (field.referenceType === 'mediaitems') {
							if (screenColumnCount) {
								const feature = space?.features?.list.find((e) => e.key === field.fieldName);
								const { label } = getSettingsForNavigationKey(field.fieldName, feature);

								const renderField = (
									<View>
										{renderReferenceField(field, items, testID, space, false, false, false, isSelfService)}
										{items.length <= 0 && videoItems.length > 0 && (
											<Text bold style={{ marginBottom: 10 }}>
												{!isEmptyString(label) ? label : field.fieldLabel}
											</Text>
										)}
										<FlatList
											data={videoItems}
											renderItem={(renderItem) => {
												return (
													<View
														style={{
															...getCardContainerStyle(screenColumnCount, renderItem.index),
															width: `${100 / screenColumnCount}%`,
															flex: undefined
														}}
													>
														<MediaItemListItem
															item={renderItem.item}
															onPress={() => {
																if (isSelfService) {
																	return;
																}
																navigation.navigate(ERoutes.Media, {
																	itemId: renderItem?.item?.id,
																	mediaType: 'mediaitem',
																	spaceId: space?.spaceId
																});
																setMediaDetail({
																	itemId: undefined,
																	viewType: 'full',
																	playbackStatus: 'paused'
																});
															}}
															testID={`${testID}_mediaitem_${renderItem.item.id}`}
														/>
													</View>
												);
											}}
											numColumns={screenColumnCount}
										/>
									</View>
								);

								return {
									type: field.fieldType,
									render: renderField
								};
							}
						}
						return {
							type: field.fieldType,
							render: renderReferenceField(field, items, testID, space, false, false, false, isSelfService)
						};
					}

					if (field.fieldType === 'socialmedia') {
						const firstSocialIndex = section.fields.findIndex((f) => f.fieldType === 'socialmedia');
						if (item && firstSocialIndex === currentIndex) {
							const renderSocials = !hasRenderedSocials;
							hasRenderedSocials = true;
							return {
								type: field.fieldType,
								render: renderSocialMedia(
									contentType,
									section.fields,
									item,
									testID,
									renderSocials ? (
										<View style={{ marginHorizontal: 5 }}>
											<FavoriteButton testID={`${testID}_button_favorite`} id={item.id} type={contentType} />
										</View>
									) : null,
									renderSocials ? (
										<ShareButton
											testID={`${testID}_button_share`}
											key={`${testID}_button_share`}
											type="expo"
											itemId={item.id}
											title={item.title}
											message={contentType === 'expo' ? item.description : contentType === 'speaker' ? item.bio : ''}
											route={ERoutes.ExpoDetails}
										/>
									) : null
								)
							};
						}
					}

					if (field.fieldType === 'location' || field.fieldType === 'contactEmail') {
						const otherButtonIndex = section.fields.findIndex((f) => {
							if (field.fieldType === 'location') {
								return f.fieldType === 'contactEmail';
							}
							if (field.fieldType === 'contactEmail') {
								return f.fieldType === 'location';
							}
							return false;
						});
						if (item && otherButtonIndex > -1 && otherButtonIndex > currentIndex) {
							if (section.fields[otherButtonIndex]) {
								return {
									type: field.fieldType,
									render: (
										<View
											style={{
												flexDirection: 'row',
												justifyContent: 'center',
												flexWrap: 'wrap'
											}}
										>
											{renderField(field, item, testID, contentType, space, isSelfService)}
											{renderField(section.fields[otherButtonIndex], item, testID, contentType, space, isSelfService)}
										</View>
									)
								};
							}
						}
						return null;
					}

					return {
						type: field.fieldType,
						render: renderField(field, item, testID, contentType, space, isSelfService, logoWidth)
					};
				});
			fields = fields.filter((e) => {
				if (e && e.render) {
					return e.render !== null && e.render !== undefined;
				}
				return false;
			});
			if (fields.length > 0) {
				if ((fields[0]?.type === 'section' || fields[0]?.type === 'mainsection') && fields.length === 1) {
					return null;
				}
				const renderfields = fields.map((f) => f?.render);
				return <HSCard key={`${testID}_section_${idx}`}>{renderfields}</HSCard>;
			}
			return null;
		});
		return sections;
	};

	return { renderSocialMedia, renderField, renderReferenceField, renderSections };
};
